const React = require('react');
const { loadable } = require('nordic/lazy');
const { node } = require('prop-types');

const { useContext } = React;

const StaticPropsContext = require('../context/static-props');

const BuyBoxOffersDesktop = loadable(() => import('./buy-box-offers-wrapper.desktop'));
const BuyBoxOffersMobile = loadable(() => import('./buy-box-offers'));

const BuyBoxOffers = ({ children, ...props }) => {
  const { deviceType } = useContext(StaticPropsContext);

  switch (deviceType) {
    case 'mobile':
      return <BuyBoxOffersMobile {...props} />;
    default:
      return <BuyBoxOffersDesktop {...props}>{children}</BuyBoxOffersDesktop>;
  }
};

BuyBoxOffers.propTypes = {
  children: node,
};

BuyBoxOffers.defaultProps = {
  children: null,
};

module.exports = BuyBoxOffers;
